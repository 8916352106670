import "../../css/Navbar/NavbarHome.css";

export default function Example() {
  return (
    <header className="bg-white pr-navbar-hidden">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/#" className="-m-1.5 p-1.5">
            <span className="sr-only">RB Espacios</span>
            <img
              className="h-12 w-auto"
              src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693270195/RBespacios/logo-rbespacios_wlcnev.png"
              alt="logo RB Espacios"
            />
          </a>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end pr-navbar-gap">
          <a href="/#" className="text-sm font-semibold leading-6 text-gray-900">
            Inicio
          </a>
          <a href="#footer" className="text-sm font-semibold leading-6 text-gray-900">
            Contacto
          </a>
        </div>
      </nav>
    </header>
  );
}
