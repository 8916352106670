import React from "react";
import CountUp from "react-countup";
import "../css/DinamicNumbers.css";

function NumerosDinamicos() {
  return (
    <section className="pr-dinamicnum-section">
      <div>
        <CountUp
          end={25.000}
          enableScrollSpy={false}
          decimals={3}
          prefix=" + "
          style={{color: "#3C74AB", textDecoration: "5px solid underline", textUnderlineOffset: "10px" }}
          className="pr-dinamicnum-media-text"
        />
        <p className="pr-dinamicnum-text">Individuos impactados</p>
      </div>
      <div>
        <CountUp
          end={250}
          enableScrollSpy={false}
          prefix=" + "
          style={{color: "#3C74AB", textDecoration: "5px solid underline", textUnderlineOffset: "10px" }}
          className="pr-dinamicnum-media-text"
        />
        <p className="pr-dinamicnum-text">Proyectos realizados</p>
      </div>
      <div>
        <CountUp
          end={100.000}
          enableScrollSpy={false}
          decimals={3}
          prefix=" + "
          style={{color: "#3C74AB", textDecoration: "5px solid underline", textUnderlineOffset: "10px" }}
          className="pr-dinamicnum-media-text"
        />
        <p className="pr-dinamicnum-text">Mobiliario vendido</p>
      </div>
    </section>
  );
}

export default NumerosDinamicos;
