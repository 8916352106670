import React from "react";
import "../css/Footer.css";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="sm:col-span-2">
            <a
              href="/"
              aria-label="Go home"
              title="Company"
              className="inline-flex items-center"
            >
              <img
                className="pr-footer-logo h-12 mr-3"
                src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693270195/RBespacios/logo-rbespacios_wlcnev.png"
                alt="Logo"
              ></img>
              <span className="ml-2 text-xl font-bold tracking-wide uppercase">
                RB Espacios
              </span>
            </a>
            <div className="mt-6 lg:max-w-sm">
              <p className="text-sm text-gray-400">
                Uno de nuestros ideales es potenciar los espacios de trabajo
                para la obtención de mayor productividad, motivación y talento
                en el desempeño de las personas.
              </p>
              <p className="mt-4 text-sm text-gray-400">
                Nos comprometemos a brindarte una planificación y ejecución
                meticulosa, adaptándonos a tus necesidades y estilo de vida, ya
                que entendemos que cada proyecto es único.
              </p>
            </div>
          </div>
          <div className="space-y-2 text-sm">
            <p className="text-base font-bold tracking-wide ">Contacto</p>
            <div className="flex">
              <i className="fa-solid fa-phone pt-1 w-3 mr-3"></i>
              <a
                href="tel:+54 11 6492 4479"
                aria-label="Our phone"
                title="Our phone"
                className="transition-colors duration-300 text-gray-400"
              >
                +54 11 6492 4479
              </a>
            </div>
            <div className="flex">
              <i className="fa-solid fa-phone pt-1 w-3 mr-3"></i>
              <a
                href="tel:+54 11 2263-9382"
                aria-label="Our phone"
                title="Our phone"
                className="transition-colors duration-300 text-gray-400"
              >
                +54 11 2263-9382
              </a>
            </div>
            <div className="flex">
              <i className="fa-solid fa-envelope pt-1 w-3 mr-3"></i>
              <a
                href="mailto:info@lorem.mail"
                aria-label="Our email"
                title="Our email"
                className="transition-colors duration-300 text-gray-400"
              >
                clientes@rbespacios.com
              </a>
            </div>
            <div className="flex">
              <i className="fa-solid fa-envelope pt-1 w-3 mr-3"></i>
              <a
                href="mailto:info@lorem.mail"
                aria-label="Our email"
                title="Our email"
                className="transition-colors duration-300 text-gray-400"
              >
                ventas@rbespacios.com
              </a>
            </div>
            <div className="flex">
              <i className="fa-solid fa-location-dot pt-1 w-3 mr-3"></i>
              <a
                href="https://www.google.com/maps"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
                className="transition-colors duration-300 text-gray-400"
              >
                Crisóstomo Álvarez 6787 - Buenos Aires
              </a>
            </div>
          </div>
          <div>
            <span className="text-base font-bold tracking-wide ">Social</span>
            <div className="flex items-center mt-1 space-x-3">
              <a
                href="https://www.facebook.com/rbespacios"
                className=" transition-colors duration-300 text-gray-400"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/rbespacios/"
                className=" transition-colors duration-300 text-gray-400"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/rb-espacios-323599230/"
                className=" transition-colors duration-300 text-gray-400"
              >
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
          <a
            className="text-sm text-gray-400"
            href="mailto:prcodecraft@gmail.com"
          >
            Developed by PR CodeCraft
          </a>
        </div>
      </div>
    </footer>
  );
}
