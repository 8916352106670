import React from "react";
import "../css/AboutUs.css";

function AboutUs() {
  return (
    <section className="pr-aboutus-section">
      <div className="pr-aboutus-container">
        <div className="pr-aboutus-images">
          <img
            className="pr-about-us-first-image"
            src="https://res.cloudinary.com/dajm6vubw/image/upload/v1696906401/RBespacios/foto_grande_qux4hg.jpg"
            alt="proyecto de oficina"
          />
          <img
            className="pr-about-us-second-image"
            src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693270195/RBespacios/logo-rbespacios_wlcnev.png"
            alt="logo"
          />
        </div>
        <div className="pr-aboutus-content">
          <h5 className="pr-aboutus-pretitle">Sobre Nosotros</h5>
          <h1 className="pr-aboutus-title">
            Hacemos realidad tus proyectos de arquitectura
          </h1>
          <p>
            Hemos incorporado la innovación y el diseño en el ADN de nuestros
            profesionales durante más de 25 años.
          </p>
          <br></br>
          <ul>
            <span>
              <i className="fa-solid fa-circle-dot pr-aboutus-list-icon"></i>
              <li>
                Acompañamiento integral en cada etapa del proceso de
                construcción.
              </li>
            </span>
            <span>
              <i className="fa-solid fa-circle-dot pr-aboutus-list-icon"></i>
              <li>
                Optimización constante de procesos y requisitos, resultando en
                proyectos dinámicos.
              </li>
            </span>
            <span>
              <i className="fa-solid fa-circle-dot pr-aboutus-list-icon"></i>
              <li>
                Soluciones personalizadas que se ajustan a tus necesidades
                específicas.
              </li>
            </span>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
