import React from "react";
import { Wave } from "react-animated-text";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "../css/PrimaryCarousel.css";

function PrimaryCarousel() {
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  return (
    <>
      <AutoplaySlider
        play={true}
        cancelOnInteraction={false}
        interval={10000}
        animation="cubeAnimation"
        className="pr-primary-carousel"
      >
        <div
          data-src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693271312/RBespacios/carrusel11_pgjd9a.png"
          className="pr-primary-carousel-img"
          alt="oficina"
        >
          <div className="pr-primary-carousel__text">
            <Wave
              text="Te ayudamos a transformar"
              direction="right"
              effect="verticalFadeIn"
              effectChange={2.2}
              iterations="1"
            />
            <div className="pr-primary-carousel-inline">
              <Wave
                text="tus "
                direction="right"
                effect="verticalFadeIn"
                effectChange={2.2}
                iterations="1"
              />
              <span className="pr-primary-carousel__text-highlight">
                <Wave
                  text="ideas "
                  direction="right"
                  effect="verticalFadeIn"
                  effectChange={2.2}
                  iterations="1"
                />
              </span>
              <Wave
                text="en realidad"
                direction="right"
                effect="verticalFadeIn"
                effectChange={2.2}
                iterations="1"
              />
            </div>
          </div>
        </div>
        <div
          data-src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693875439/RBespacios/2_yuckv5.png"
          className="pr-primary-carousel-img"
          alt="mesa"
        >
          <div className="pr-primary-carousel__text">
            <Wave
              text="Diseñamos, fabricamos y proveemos"
              direction="right"
              effect="verticalFadeIn"
              effectChange={2.2}
              iterations="1"
            />
            <div className="pr-primary-carousel-inline">
              <span className="pr-primary-carousel__text-highlight">
                <Wave
                  text="equipamientos "
                  direction="right"
                  effect="verticalFadeIn"
                  effectChange={2.2}
                  iterations="1"
                />
              </span>
              <Wave
                text="para empresas y particulares"
                direction="right"
                effect="verticalFadeIn"
                effectChange={2.2}
                iterations="1"
              />
            </div>
          </div>
        </div>
        <div
          data-src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693271312/RBespacios/carrusel13_ukjegs.png"
          className="pr-primary-carousel-img"
          alt="casa"
        >
          <div className="pr-primary-carousel__text">
          <div className="pr-primary-carousel-inline">
            <Wave
              text="Brindamos "
              direction="right"
              effect="verticalFadeIn"
              effectChange={2.2}
              iterations="1"
            />
              <span className="pr-primary-carousel__text-highlight">
                <Wave
                  text="asesoramiento "
                  direction="right"
                  effect="verticalFadeIn"
                  effectChange={2.2}
                  iterations="1"
                />
              </span>
              <Wave
                text="desde el estudio y "
                direction="right"
                effect="verticalFadeIn"
                effectChange={2.2}
                iterations="1"
              />
            </div>
            <br/>
            <div className="pr-primary-carousel-inline">
              <span className="pr-primary-carousel__text-highlight">
                <Wave
                  text="planificación "
                  direction="right"
                  effect="verticalFadeIn"
                  effectChange={2.2}
                  iterations="1"
                />
              </span>
              <Wave
                text="del proyecto arquitectónico"
                direction="right"
                effect="verticalFadeIn"
                effectChange={2.2}
                iterations="1"
              />
            </div>
            <br/>
            <div className="pr-primary-carousel-inline">
            <Wave
              text="hasta la "
              direction="right"
              effect="verticalFadeIn"
              effectChange={2.2}
              iterations="1"
            />
              <span className="pr-primary-carousel__text-highlight">
                <Wave
                  text="ejecución "
                  direction="right"
                  effect="verticalFadeIn"
                  effectChange={2.2}
                  iterations="1"
                />
              </span>
              <Wave
                text="en obra"
                direction="right"
                effect="verticalFadeIn"
                effectChange={2.2}
                iterations="1"
              />
            </div>
          </div>
        </div>
      </AutoplaySlider>
    </>
  );
}

export default PrimaryCarousel;
