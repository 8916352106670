import "../css/GaleryImages.css";

function GaleryImages() {
  return (
    <>
      <div className="margin-20px-bottom margin-40px-top xs-margin-30px-bottom text-center pr-gi-text">
        <h3 className="font-size13 text-uppercase margin-15px-bottom xs-margin-5px-bottom font-weight-400 letter-spacing-3 word-spacing-3 text-theme-color">
          Galeria de Imagenes
        </h3>
        <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
          Proyectos
        </h4>
      </div>
      <section className="pr-galery-section">
        <div className="pr-gi-display">
          <div className="max-w-sm rounded overflow-hidden">
            <img
              className="w-full short-img"
              src="https://res.cloudinary.com/dajm6vubw/image/upload/v1697485733/RBespacios/gi5_t6epa2.png"
              alt="Sunset in the mountains"
            ></img>
            <img
              className="w-full short-img"
              src="https://res.cloudinary.com/dajm6vubw/image/upload/v1694128114/RBespacios/gi3_wazkrh.jpg"
              alt="Sunset in the mountains"
            ></img>
          </div>
          <div className="max-w-sm rounded overflow-hidden pr-gi-hidden">
            <img
              className="w-full large-img"
              src="https://res.cloudinary.com/dajm6vubw/image/upload/v1694128113/RBespacios/gi2_y07kkz.jpg"
              alt="Sunset in the mountains"
            ></img>
          </div>
          <div className="max-w-sm rounded overflow-hidden">
            <img
              className="w-full short-img"
              src="https://res.cloudinary.com/dajm6vubw/image/upload/v1694129027/RBespacios/gi4_fbkxxw.png"
              alt="Sunset in the mountains"
            ></img>
            <img
              className="w-full short-img"
              src="https://res.cloudinary.com/dajm6vubw/image/upload/v1694129489/RBespacios/gi6_b8pwdo.png"
              alt="Sunset in the mountains"
            ></img>
          </div>
        </div>
      </section>
    </>
  );
}

export default GaleryImages;
