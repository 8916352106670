import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import "../../css/Navbar/NavbarInformation.css";

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="pr-navbar-backgroundcolor">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1 pr-navbar-textcolor">
        <img
              className="h-12 w-auto pr-mr-1 pr-image-display"
              src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693270195/RBespacios/logo-rbespacios_wlcnev.png"
              alt="logo RB Espacios"
            />
          <a
            href="https://www.facebook.com/rbespacios"
            className="-m-1.5 p-1.5 pr-navbar-center-icon"
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only">RB Espacios Facebook</span>
            <i className="fa-brands fa-facebook-f pr-navbar-icon__space"></i>
          </a>
          <a
            href="https://www.instagram.com/rbespacios/"
            className="-m-1.5 p-1.5 pr-navbar-center-icon "
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only">RB Espacios Instagram</span>
            <i className="fa-brands fa-instagram pr-navbar-icon__space"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/rb-espacios-323599230/ "
            className="-m-1.5 p-1.5 pr-navbar-center-icon "
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only">RB Espacios LinkedIn</span>
            <i className="fa-brands fa-linkedin-in pr-navbar-icon__space"></i>
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 pr-navbar-textcolor"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end pr-navbar-gap pr-navbar-textcolor">
          <span className="text-sm font-semibold leading-6">
            <i className="fa-solid fa-phone pr-navbar-icon__space"></i>+54 11 6492 4479
          </span>
          <span className="text-sm font-semibold leading-6">
            <i className="fa-solid fa-phone pr-navbar-icon__space"></i>+54 11 2263-9382
          </span>
          <span className="text-sm font-semibold leading-6">
            <i className="fa-solid fa-envelope pr-navbar-icon__space"></i>
            clientes@rbespacios.com
          </span>
          <span className="text-sm font-semibold leading-6">
            <i className="fa-solid fa-envelope pr-navbar-icon__space"></i>
            ventas@rbespacios.com
          </span>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-12 w-auto"
                src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693270195/RBespacios/logo-rbespacios_wlcnev.png"
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="py-6 pr-navbar__inlinegrid">
                <span className="text-sm font-semibold leading-6 text-gray-900">
                  <i className="fa-solid fa-phone pr-navbar-icon__space"></i>+54 11 6492 4479
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-900">
                  <i className="fa-solid fa-phone pr-navbar-icon__space"></i>+54 11 2263-9382
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-900">
                  <i className="fa-solid fa-envelope pr-navbar-icon__space"></i>
                  clientes@rbespacios.com
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-900">
                  <i className="fa-solid fa-envelope pr-navbar-icon__space"></i>
                  ventas@rbespacios.com
                </span>
              </div>
              <div className="py-6 pr-navbar-media__socialicons">
                <a
                  href="https://www.facebook.com/rbespacios"
                  className="-m-1.5 p-1.5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">RB Espacios Facebook</span>
                  <i className="fa-brands fa-facebook-f pr-navbar-icon__space"></i>
                </a>
                <a
                  href="https://www.instagram.com/rbespacios/"
                  className="-m-1.5 p-1.5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">RB Espacios Instagram</span>
                  <i className="fa-brands fa-instagram pr-navbar-icon__space"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/rb-espacios-323599230/ "
                  className="-m-1.5 p-1.5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">RB Espacios LinkedIn</span>
                  <i className="fa-brands fa-linkedin-in pr-navbar-icon__space"></i>
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
