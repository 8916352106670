import React from "react";
import Slider from "react-infinite-logo-slider";
import "../css/ClientLogos.css";

function ClientLogos() {
  return (
    <div className="pr-clientlogos-content">
        <Slider
      width="250px"
      duration={40}
      pauseOnHover={true}
      blurBorders={false}
      blurBoderColor={"#fff"}
      className="pr-clientlogos-slider"
    >
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693268692/RBespacios/osmecon_n86qqx.png"
          alt="any"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693268578/RBespacios/mercado_libre_rngkda.png"
          alt="any2"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1692581146/RBespacios/inviu_gvfrdj.png"
          alt="any3"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693268614/RBespacios/citibank_dgknbg.png"
          alt="any3"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1692581146/RBespacios/La_anonima_hx5243.png"
          alt="any3"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1692581145/RBespacios/banco_galicia_g1stgm.png"
          alt="any3"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693268691/RBespacios/banco_supervielle_qeh4rd.png"
          alt="any3"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1692581145/RBespacios/pwc_odjppa.png"
          alt="any3"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1692581145/RBespacios/conicet_vddqeu.png"
          alt="any3"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1692581145/RBespacios/santander_rio_gcao96.png"
          alt="any3"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1692581145/RBespacios/anaerobicos_owj1wq.png"
          alt="any3"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693268551/RBespacios/atento_iokod3.png"
          alt="any3"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693268456/RBespacios/movistar_zyykwu.png"
          alt="any3"
          className="w-36 pr-clientlogos-img"
        />
      </Slider.Slide>
    </Slider>
    </div>
  );
}

export default ClientLogos;
