import React from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/custom-animations/fall-animation.css";
import "../css/SecondaryCarousel.css";

function SecondaryCarousel() {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  return (
    <section className="pr-sc-section">
      <div className="pr-sccarousel-carousel">
        <AutoplaySlider
          play={true}
          cancelOnInteraction={false}
          interval={6000}
          animation="fallAnimation"
          className="pr-sc-carousel"
        >
          <div data-src="https://res.cloudinary.com/dajm6vubw/image/upload/v1692840459/RBespacios/carrusel21_tyg4ox.png" className="pr-sc-img" alt="oficina 1">
            <p className="pr-sc-text">CABA</p>
          </div>
          <div data-src="https://res.cloudinary.com/dajm6vubw/image/upload/v1696906800/RBespacios/mobiliario_1_mixwn6.jpg" className="pr-sc-img" alt="oficina 2">
            <p className="pr-sc-text">CABA</p>
          </div>
          <div data-src="https://res.cloudinary.com/dajm6vubw/image/upload/v1692840459/RBespacios/carrusel23_m9jk74.png" className="pr-sc-img" alt="sala de estudio">
            <p className="pr-sc-text">CABA</p>
          </div>
        </AutoplaySlider>
      </div>
      <div className="pr-sccarousel-grouptext">
        <div className="pr-sccarousel-text">profesionalismo.</div>
        <div className="pr-sccarousel-text">compromiso.</div>
        <div className="pr-sccarousel-text">calidad.</div>
        <div className="pr-sccarousel-text">personalización.</div>
        <div className="pr-sccarousel-text">innovación.</div>
        <div className="pr-sccarousel-text">transparencia.</div>
        <div className="pr-sccarousel-text">cercanía.</div>
      </div>
    </section>
  );
}

export default SecondaryCarousel;
