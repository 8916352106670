import "./App.css";
import NavbarInformation from "./components/Navbar/NavbarInformation";
import NavbarHome from "./components/Navbar/NavbarHome";
import DinamicNumbers from "./components/DinamicNumbers";
import SecondaryCarousel from "./components/SecondaryCarousel";
import ClientLogos from "./components/ClientLogos";
import PrimaryCarousel from "./components/PrimaryCarousel";
import Footer from "./components/Footer";
import AboutUs from "./components/AboutUs";
import GaleryImages from "./components/GaleryImages";
import { useFirebaseApp } from "reactfire";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../src/firebase-config"

function App() {
  const app = initializeApp(firebaseConfig);
  const firebase = useFirebaseApp();
  console.log(firebase);
  console.log("app", app);
  return (
    <div className="App">
      <NavbarInformation />
      <NavbarHome />
      <PrimaryCarousel />
      <AboutUs />
      <GaleryImages/>
      <DinamicNumbers /> 
      <SecondaryCarousel />
      <ClientLogos />
      <a href="https://wa.me/+541164924479/?text=Hola, los contacto porque me gustaría..." className="pr-app-whatsapp" target="_blank" rel="noopener noreferrer">
        <img src="https://res.cloudinary.com/dajm6vubw/image/upload/v1693269359/RBespacios/whatsapp-icon_ycnnhn.png" alt="whatsapp icon"/>
      </a>
      <Footer />
    </div>
  );
}

export default App;
